<template>
  <ul class="list-group list-group-horizontal">
    <li
      v-for="locale in supportedLocales"
      :key="locale"
      @click="switchLocale(locale)"
      class="list-group-item col-6"
    >
      <img :src="require('@/assets/flags/' + locale + '.png')" />
    </li>
  </ul>
</template>

<script>
import { Trans } from "@/plugins/Translation";
export default {
  name: "LocaleSwitcher",
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",")
    };
  },
  computed: {
    supportedLocales() {
      return Trans.supportedLocales;
    }
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        const to = this.$router.resolve({ params: { locale } });
        return Trans.changeLocale(locale).then(() => {
          this.$router.push(to.location);
        });
      }
    },
    flagURL(locale) {
      return "../assets/flags/" + locale + ".png";
    }
  }
};
</script>

<style scoped>
li {
  text-decoration: underline;
  color: #459ce7;
  cursor: pointer;
}
</style>
