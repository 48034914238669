<template>
  <div
    class="modal bd-example-modal-lg"
    id="myModal"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
  >
    <div id="overlay"></div>
    <div class="modal-dialog">
      <div
        class="modal-content d-flex justify-content-center align-items-center"
        style="height: 300px"
      >
        {{ message }}
        <div
          class="spinner-border spinner-border-xl text-primary mt-3"
          role="status"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalLoading",
  props: {
    showModal: Boolean,
    message: String
  },
  watch: {
    showModal: function(val) {
      if (val) {
        document.getElementById("myModal").style.display = "block";
      } else {
        document.getElementById("myModal").style.display = "none";
      }
    }
  }
};
</script>

<style scoped>
.bd-example-modal-lg .modal-dialog {
  display: table;
  position: relative;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  width: 50%;
  font-size: 1.6em;
  top: calc(25% - 0px);
  border: 5px solid green;
}

.bd-example-modal-lg .modal-dialog .modal-content {
  background-color: transparent;
  border: black;
}
</style>
