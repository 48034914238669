import axios from "axios";
export default class toolsUsersAPI {
  constructor(data) {
    this.url = "https://toolsusers." + data.base_url + "/api/v1/admin/user";
    this.urlUser = "https://toolsusers." + data.base_url + "/api/v1/user";
    this.token = data.token;
    this.headerBearerToken = {
      Authorization: "Bearer " + this.token
    };
  }

  async getUsers() {
    try {
      const response = await axios.get(this.url, {
        headers: this.headerBearerToken
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  async getPersonalData() {
    try {
      const response = await axios.get(this.urlUser, {
        headers: this.headerBearerToken
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  async createUser(data) {
    try {
      const response = await axios.post(this.url, data, {
        headers: this.headerBearerToken
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  async updateUser(data, id) {
    try {
      const response = await axios.put(this.url + "/" + id, data, {
        headers: this.headerBearerToken
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  async updatePersonalData(data) {
    try {
      const response = await axios.put(this.urlUser, data, {
        headers: this.headerBearerToken
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  async deleteUser(id) {
    try {
      const response = await axios.delete(this.url + "/" + id, {
        headers: this.headerBearerToken
      });
      return response.data;
    } catch (error) {
      alert(error);
    }
  }
}
