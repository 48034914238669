<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">{{ $t("Copla.Views.PersonalData.Title") }}</h1>
        <div class="border rounded secciones mt-4" id="data">
          <h2>{{ $t("Copla.Common.Subtitles.Data") }}</h2>
          <form id="data" autocomplete="nope" v-on:submit="checkForm">
            <div class="form-group row mt-4">
              <label
                for="email"
                class="col-lg-2 col-form-label text-left text-lg-center"
                >{{ $t("Copla.Common.Form.Email") }}</label
              >
              <div class="col-lg-10">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  min="1"
                  v-model="email"
                  autocomplete="username"
                />
              </div>
            </div>
            <div class="form-group row mt-4">
              <label
                for="password1"
                class="col-lg-2 col-form-label text-left text-lg-center"
                >{{ $t("Copla.Common.Form.Password") }}</label
              >
              <div class="col-lg-10">
                <input
                  type="password"
                  class="form-control"
                  name="password1"
                  id="password1"
                  min="1"
                  v-model="password1"
                  autocomplete="new-password"
                />
              </div>
            </div>
            <div class="form-group row mt-4">
              <label
                for="password"
                class="col-lg-2 col-form-label text-left text-lg-center"
                >{{ $t("Copla.Common.Form.Password2") }}</label
              >
              <div class="col-lg-10">
                <input
                  type="password"
                  class="form-control"
                  name="password2"
                  id="password2"
                  min="1"
                  v-model="password2"
                  autocomplete="new-password"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="text-center">
                  <button
                    type="submit"
                    id="save"
                    name="save"
                    class="btn btn-success mr-2"
                  >
                    {{ $t("Copla.Common.Buttons.Save") }}
                  </button>
                </div>
              </div>
            </div>
            <div v-if="message != ''" class="mt-3 updated-box">
              {{ message }}
            </div>
          </form>
        </div>
      </div>
    </div>
    <modal-loading :showModal="showModalLoading" :message="messageModal" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import toolsUsersAPI from "../js/toolsUsersAPI";
import MainMenu from "../components/MainMenu";
import ModalLoading from "../components/ModalLoading.vue";
export default {
  name: "PersonalData",
  components: {
    MainMenu,
    ModalLoading
  },
  data() {
    return {
      id: -1,
      email: this.$store.state.user,
      oldEmail: "",
      password1: "",
      password2: "",
      usersAPI: new toolsUsersAPI({
        base_url: process.env.VUE_APP_BASE_URL,
        token: this.$store.state.accessToken
      }),
      showModalLoading: false,
      messageModal: "",
      message: ""
    };
  },
  computed: {
    dataSend: function() {
      return { _login: this.email, _password: this.password1 };
    }
  },
  methods: {
    ...mapActions(["logout"]),
    cleanPasswords() {
      this.password1 = "";
      this.password2 = "";
    },
    showModal(status, message = "") {
      this.messageModal = message;
      this.showModalLoading = status;
    },
    updateUser() {
      if (this.email != this.oldEmail) {
        if (confirm(this.$t("Copla.Common.Messages.ConfirmLogout"))) {
          this.message = "";
          this.showModal(true, this.$t("Copla.Common.Modal.Updating"));
          this.usersAPI
            .updatePersonalData(this.dataSend, this.id)
            .then(response => {
              this.showModal(false);
              this.cleanPasswords();
              if (!response.error) {
                this.message = this.$t("Copla.Common.Modal.UserUpdated");
                this.logout();
              } else {
                this.loadData();
                this.message = "Error: " + response.data;
              }
            });
        }
      } else {
        this.message = "";
        this.showModal(true, this.$t("Copla.Common.Modal.Updating"));
        this.usersAPI
          .updatePersonalData(this.dataSend, this.id)
          .then(response => {
            this.showModal(false);
            this.cleanPasswords();
            if (!response.error) {
              this.message = this.$t("Copla.Common.Modal.UserUpdated");
              this.logout();
            } else {
              this.loadData();
              this.message = "Error: " + response.data;
            }
          });
      }
    },
    checkForm(e) {
      this.message = "";
      let passwordEqual = this.password1 == this.password2;
      let emailBlank = this.email == "";
      if (!passwordEqual) {
        alert(this.$t("Copla.Common.Errors.PasswordsEquals"));
      } else if (emailBlank) {
        alert(this.$t("Copla.Common.Errors.EmailFieldEmpty"));
      } else {
        this.updateUser();
      }
      e.preventDefault();
    },
    loadData() {
      this.showModal(true, this.$t("Copla.Common.Modal.Loading"));
      let self = this;
      this.usersAPI.getPersonalData().then(response => {
        this.showModal(false);
        if (!response.error) {
          self.email = response.data.email;
          this.oldEmail = this.email;
        } else {
          this.showModal(false);
          this.error = "Error: " + response.data;
        }
      });
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

<style scoped>
.updated-box {
  color: red;
  font-weight: bold;
}
.active {
  background-color: yellow !important;
  color: black;
}
.reload {
  font-family: Lucida Sans Unicode;
  float: right;
  cursor: pointer;
}
h1 {
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.secciones {
  padding: 10px;
}
#messages {
  min-height: 100px;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}
</style>
